import { CMSHeroBanner } from '@cms-components/hero-banner/';
import { CMSImageFaqPanelWrapper } from "@cms-components/image-faq-panel/image-faq-panel";
import { PageRte } from '@cms-components/page-rte/page-rte';
import { MappedAccordion } from './accordion';
import { MappedContentPanel } from './content-panel';
import { MappedFeatureCards } from './feature-cards';
import { FormPanel } from './form-panel';
import { FormPanelBaseline } from './form-panel-baseline';
import { MappedListPanel } from './list-panel';
import {
  BoxedPromoWrapper,
  FullwidthPromoWrapper, MappedPromoCards, MappedSimplePromo
} from './promos';
import { CMSHeroBannerSPZ } from '@cms-components/hero-banner-spz/';
import { CMSHeroBannerSlider } from './hero-banner-slider';
import { SolutionsList } from '@cms-components/solutions-list/';
import { MappedTestimonialSliderAccordion } from '@cms-components/testimonial-slider/';
import { MappedFeaturesAccordion } from '@cms-components/features-accordion/';
import { MappedFAQAccordion } from '@cms-components/faq-accordion/';
import { MappedImageGrid } from '@cms-components/image-grid/';
import { TechStackComp } from '@cms-components/tech-stack/';
import { FeaturedCards } from '@cms-components/featured-cards/';
import { LeadSection } from '@cms-components/lead-section';

export const component_mapper: any = {
  boxed_promo: BoxedPromoWrapper,
  simple_promo: MappedSimplePromo,
  full_width_promo: FullwidthPromoWrapper,
  promo_cards: MappedPromoCards,
  content_panel: MappedContentPanel,
  hero_banner: CMSHeroBanner,
  form_banner: CMSHeroBanner,
  simple_banner: CMSHeroBanner,
  standard_banner: CMSHeroBanner,
  overhang_banner: CMSHeroBanner,
  list_panel: MappedListPanel,
  accordion: MappedAccordion,
  form_panel: FormPanel,
  form_baseline_panel: FormPanelBaseline,
  featured_cards: MappedFeatureCards,
  page_rte: PageRte,
  image_faq_panel: CMSImageFaqPanelWrapper,
  hero_panel: CMSHeroBannerSPZ,
  hero_banner_slider: CMSHeroBannerSlider,
  testimonial_panel: MappedTestimonialSliderAccordion,
  features_panel: MappedFeaturesAccordion,
  faq_accordion_panel: MappedFAQAccordion,
  badge_panel: MappedImageGrid,
  case_panel: MappedFeaturesAccordion,
  lead_section_panel: LeadSection,
  solutions_list: SolutionsList,
  integration_panel: TechStackComp,
  demo_panel: FeaturedCards,
};

export * from './accordion';
export * from './content-panel';
export * from './feature-tile';
export * from './footer';
export * from './header';
export * from './hero-banner';
export * from './hero-banner-slider';
export * from './image-faq-panel';
export * from './list-panel';
export * from './page-rte';
export * from './promos';
export * from './hero-banner-spz';
export * from './testimonial-slider';
export * from './features-accordion';
export * from './image-grid';
export * from './tech-stack';
export * from './featured-cards';
export * from './faq-accordion';

