import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { CustomerTestimonialProps } from '../../testimonial-slider.interface';
import { CustomerTestimonialItem } from '@shared/components/testimonial-slider/testimonial-slider.interface';
import { RichText } from '@src/shared/components';
import {
    SectionContainer,
    HeadlineStyled,
    TestimonialSplideStyled,
    SplideContainer,
    TestimonialCopyStyled,
    NameStyled,
    DesignationStyled,
    ContentStyled,
    TestimonialImageStyled,
    SplideSlideStyled,
    LogoStyled
} from './large-testimonial-slider.styles';

export const LargeDarkTestimonialSlider = ({
  itemList
}: CustomerTestimonialProps) => {
  return (
    <SectionContainer data-testid="large-testimonial-slider_container">
      <CMSComponentWrapper
        horizontalPadding={0}
      >
        <TestimonialSplideStyled
            options={{
              autoHeight: true,
              perMove: 1,
              perPage: 1,
            }}
          >
            {itemList.map((item: CustomerTestimonialItem, index: number) => {
              return (
                <SplideSlideStyled key={`testimonial__item-${index}`}>
                  <SplideContainer>
                    <TestimonialImageStyled></TestimonialImageStyled>
                    <TestimonialCopyStyled>
                      <ContentStyled>
                        <RichText content={item.customer_quote} />
                      </ContentStyled>
                      <NameStyled>{item.customer_name}</NameStyled>
                      <DesignationStyled>{item.customer_designation}</DesignationStyled>
                      {item.logo?.image?.url && (
                        <LogoStyled src={item.logo?.image?.url} alt={item.logo?.image_alt_text} />
                      )}
                    </TestimonialCopyStyled>
                  </SplideContainer>
                </SplideSlideStyled>
              )
            })}
          </TestimonialSplideStyled>
      </CMSComponentWrapper>
    </SectionContainer>
  );
};
