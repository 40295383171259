import React, { useEffect, useRef, useState } from 'react';
import {
  NavItemState,
  NavMenuItemProps,
} from '@cms-components/header/mega-components/nav/nav.interface';
import { useNav } from '@cms-components/header/context/nav.context';
import {
  MenuItemLabel,
  NavItemIconStyled,
  NavItemLinkStyled,
  NavListItemStyled,
  BackDrop
} from '@cms-components/header/mega-components/nav-menu-item/nav-menu-item.styles';
import BasicLink from '@shared/components/basic-link';
import { useMainNav } from '@cms-components/header/context/main-nav.context';
import { CMSNavPanel } from '@cms-components/header/mega-components/nav-panel/nav-panel';

export const NavMenuItem = ({
  menuItem,
  featured_section,
  demo_button,
  sign_in_link,
  id,
  theme
}: NavMenuItemProps) => {
  const { isOpen, toggle, closeAll } = useNav(id);
  const { closeNav } = useMainNav();
  const { navigation_title, dropdown_menu_sections } = menuItem || {};
  const state: NavItemState = isOpen ? 'open' : 'closed';
  const closeNavHandler = ($e: MouseEvent) => {
    const $target = $e.target as Element;
    if (!$target.closest('#main-header')) {
      closeAll();
    }
  };

  return (
    <NavListItemStyled state={state} className={`${navigation_title.toLowerCase().replace(' ', '-')}`}>
      <NavItemLinkStyled
        role="menuitem"
        onClick={() => {closeAll();toggle(); }}
        color={theme}
      >
        <MenuItemLabel state={state}>{navigation_title.toLowerCase() === 'government' && <img src="https://res.cloudinary.com/spiralyze/image/upload/v1742460681/assembly/6004/Frame.svg" alt="government"/>}{navigation_title}</MenuItemLabel>
        <NavItemIconStyled state={state} />
      </NavItemLinkStyled>
      <CMSNavPanel
          state={state}
          menu_dropdown={dropdown_menu_sections}
          featured_section={featured_section}
          closeAll={closeAll}
          demo_button={demo_button}
          sign_in_link={sign_in_link}
        />
      {isOpen && <BackDrop onClick={()=>closeAll()}/>}
    </NavListItemStyled>
  );
};
