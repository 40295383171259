// Contentstack internal type mapper
export const contentStackInternalTypeMapper = {
  Contentstack_standard_promo: 'standard_promo',
  Contentstack_quote_promo: 'quote_promo',
  Contentstack_simple_promo: 'simple_promo',
  Contentstack_promo_cards: 'promo_cards',
};

export const contentStackBannerMapper: { [key: string]: string | null } = {
  overhang_banner: 'reference',
  form_banner: 'reference',
  standard_banner: 'hero_banner',
  simple_banner: null,
};

export enum CONTENTSTACK_CONTENT_TYPES {
  simplePromo = 'simple_promo',
}

export const contentStackComponentMapper: { [key: string]: string | null } = {
  overhang_banner: 'reference',
  form_banner: 'reference',
  standard_banner: 'hero_banner',
  simple_banner: null,
  accordion: 'accordion',
  content_panel: 'content_panel',
  featured_cards: 'featured_cards',
  form_panel: 'form_panel',
  form_baseline_panel: 'form_baseline_panel',
  full_width_promo: 'full_width_promo',
  simple_promo: 'simple_promo',
  box_promo: 'box_promo',
  promo_cards: 'promo_cards',
  list_panel: 'list_panel',
  hero_panel: 'hero_panel',
  testimonial_panel: 'testimonial_panel',
  features_panel: 'features_panel',
  badge_panel: 'badge_panel',
  case_panel: 'case_panel',
  integration_panel: 'integration_panel',
  demo_panel: 'demo_panel',
  lead_section_panel: 'lead_section_panel',
  faq_accordion_panel: 'faq_accordion_panel',
  hero_banner_slider: 'hero_banner_slider',
  solutions_list: 'solutions_list',
};
