import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '@shared/components';
import CMSComponentWrapper from '@shared/components/component-wrapper';

import { MainNavProvider } from '../../context/main-nav.context';
import { MainNavProps } from './main-nav.interface';
import {
  DesktopOptions,
  MainNavIconLink,
  MainNavStyled,
  MainNavWrapper,
} from './main-nav.styles';
import { Nav } from '../nav';
import { TopMenu } from '../top-menu/top-menu';
import { usePageHeaderStyle } from '@shared/context/pageHeaderStyle.context';
import BasicLink from '@shared/components/basic-link';

export const MegaMainNav = ({ data }: MainNavProps) => {
  const { headerData } = data || {};
  const { demo_button, assembly_logo } = headerData || {};
  const { link_url: demoUrl, link_text: demoLabel } = demo_button || {};
  const { sign_in_link } = headerData || {};
  const {
    link_text: signInText,
    link_url: signInUrl,
    external_link: signInIsExternal,
  } = sign_in_link || {};
  const { currentColor } = usePageHeaderStyle();

  const [ theme, setTheme ] = useState(currentColor);

  useEffect(() => {
    setTheme(currentColor);

    const handleScroll = () => {
      if (window.scrollY > 10) {
        setTheme('light');
      } else {
        setTheme(currentColor);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentColor]);

  return (
    <MainNavWrapper data-testid="main-nav__wrapper" color={theme}>
      <MainNavProvider>
        <CMSComponentWrapper maxWidth={1240}>
          <MainNavStyled>
            <TopMenu demo={demo_button} logo={assembly_logo} theme={theme} sign_in_link={sign_in_link}/>
            <Nav {...data} theme={theme}/>
            <DesktopOptions>
              <MainNavIconLink color={theme}>
                <BasicLink
                  text={signInText}
                  url={signInUrl}
                  isExternal={signInIsExternal}
                />
              </MainNavIconLink>
              <PrimaryButton
                ariaLabel="get a demo"
                label={demoLabel}
                icon={theme === 'dark' ? 'white' : 'text'}
                size={'small'}
                fontSize={'14px'}
                path={demoUrl}
                variant={theme === 'light' ? 'contained' : 'outlined'}
                color={'miami'}
              />
            </DesktopOptions>
          </MainNavStyled>
        </CMSComponentWrapper>
      </MainNavProvider>
    </MainNavWrapper>
  );
};
