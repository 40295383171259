import React, { useEffect, useRef  } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';

import { NavMenuItem } from '@cms-components/header/mega-components/nav-menu-item/nav-menu-item';
import { CSLink, CSMenuLink } from '@shared/interfaces/links.resolver';
import { NavProvider } from '../../context/nav.context';
import * as Styled from './nav.styles';
import { useMainNav } from '../../context/main-nav.context';
import {
  MegaMenuSections,
  NavMainProps,
  FeaturedSection
} from '@cms-components/header/mega-components/main-nav/main-nav.interface';

interface MenuItemProps{
  mega_menu_navigation: MegaMenuSections[]
  featured_section: FeaturedSection[]
  sign_in_link: CSLink;
  demo_button: CSLink;
  theme: 'dark' | 'light'
}

const renderMenuItems = ({ mega_menu_navigation, featured_section, theme, demo_button, sign_in_link }: MenuItemProps) => {
  return mega_menu_navigation?.map((item, index: number) => {
    return (
      <NavMenuItem
        key={index}
        id={index}
        menuItem={item}
        featured_section={featured_section}
        theme={theme}
        demo_button={demo_button}
        sign_in_link={sign_in_link}
      />
    );
  });
};

export const Nav = ({ allMenuSections, headerData, theme }: NavMainProps) => {
  const { navState, openNav, closeNav } = useMainNav();
  const isDesktop = useBreakpoint(up('lg'));

  const { mega_menu_navigation, featured_section,demo_button, sign_in_link } =  headerData;
  const navListRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (isDesktop) {
      return openNav();
    }
    return closeNav();
  }, [isDesktop, openNav, closeNav]);


  const handleNavClick = (event: React.MouseEvent) => {
    if (navListRef.current && navListRef.current.contains(event.target as Node)) {
      return;
    }
    closeNav();
  };

  return (
    <NavProvider multiple={!isDesktop}>
      <Styled.Nav state={navState} color={theme} onClick={handleNavClick}>
        <Styled.NavList ref={navListRef}>
          {allMenuSections && renderMenuItems({ mega_menu_navigation, featured_section, theme, demo_button, sign_in_link })}
        </Styled.NavList>
      </Styled.Nav>
    </NavProvider>
  );
};
