import styled from '@emotion/styled';
import ExpandLessMore from '@mui/icons-material/ExpandMoreOutlined';

import { getStyleSizes } from '@styles/styles.utils';
import { color_black, color_light_grey, color_white } from '@styles/colors';

import { NavItemIconStyledProps } from '@cms-components/header/mega-components/nav/nav.interface';
import { mq_min, mq_max } from '@styles/breakpoints';
import {
  BasicLinkStyledProps,
  MenuItemLabelProps,
  NavItemLinkStyledProps,
} from '@cms-components/header/components/nav-menu-item/nav-menu-item.interface';

export const NavListItemStyled = styled.li<MenuItemLabelProps>`
  ${({ state }) =>
    `padding: ${state === 'open' ? getStyleSizes([16,0,0]) : getStyleSizes([14,0])}
  `};
  border-bottom: ${getStyleSizes(1)} solid ${color_light_grey};
  width: 100%;
  position: relative;

  ${mq_min('lg')} {
    border: 0;
    padding: ${getStyleSizes([18.5,0])};
  }

  ${mq_max('lg')} {
    border: 0;
  }


  &:has(.open):before{
    ${mq_min('md')} {
      display: block;
    }
  }

  &:before{
    content: '';
    height: 1px;
    background-color: #25C9EF;
    bottom: 1px;
    position: absolute;
    width: calc(100% - 7px);
    display: none;
    z-index: 1;
    ${mq_max('lg')} {
      display: none !important;
    }
  }

  &.contact{
    border-bottom: none;

   &:has(.open) + .government:before{
     ${mq_max('lg')} {
      display: none !important;
     }
   }
  }

  &.government{
    .item-section{
      width: 100%;
      padding: 30px 48px 4px 41px;
    }
    .featured-section{
      display:none;

    }
    > span{
      border-left: 1px solid #DDDEE1;
      margin-left: 0px;
      padding-left: ${getStyleSizes(20)};
      color: #119FC0 !important;
      @media(max-width: 1110.98px){
        padding-left: ${getStyleSizes(8)};
        margin-left: 0px;
      }
      ${mq_max('lg')}{
        justify-content: flex-start;
        padding-left: ${getStyleSizes(20)};
      }
      > div{
        color: #119FC0 !important;
      }
      svg{
        ${mq_max('lg')}{
            margin-left: auto;
        }
        path{
          fill: #119FC0 !important;
        }
      }
      img{
        margin-right:${getStyleSizes(8)};
      }
    }
    &:before{
      width: calc(100%);
      left: -5px;
      ${mq_max('lg')}{
        display: block !important;
        bottom: auto;
        top: -1px;
        width: 95%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(68, 77, 94, 0.40);
        opacity: 0.4;
      }
        ${mq_max('md')}{
         width: 89%;
        }
    }
    .item-section{
      width: 100%;
      padding: 30px 48px 4px 41px;
      ${mq_max('lg')}{
          padding: 24px 20px 32px;
      }
      ${mq_max('md')}{
          padding: 27px 20px 32px;
      }
    }
    .section-row{
      max-width: 100%;
      flex-wrap: wrap;
      .section-col{
        width: calc(16.666666% - 10px);
        ${mq_max('lg')}{
          width: 33.3333333%;
          max-width: 221px;
        }
        ${mq_max('md')}{
          margin-bottom: 32px;
        }
        ${mq_max('lg')}{
          width: 33.3333333%;
          max-width: 221px;
        }
        ${mq_max('md')}{
          margin-bottom: 32px;
        }
        &:nth-of-type(1){
          ${mq_max('lg')}{
            order: 1;
          }
        }
        &:nth-of-type(2){
          ${mq_max('lg')}{
            order: 2;
          }
        }
        &:nth-of-type(3){
          ${mq_max('lg')}{
              order: 3;
          }
          ${mq_max('md')}{
              order: 5;
          }
        }
        &:nth-of-type(4){
          ${mq_max('lg')}{
              order: 6;
          }
        }
        &:nth-of-type(7){
          ${mq_max('lg')}{
              order: 4;
          }
          ${mq_max('md')}{
              order: 3;
          }
        }
        &:nth-of-type(8){
          ${mq_max('lg')}{
              order: 5;
          }
          ${mq_max('md')}{
              order: 4;
          }
        }
        &:nth-of-type(9){
          ${mq_max('lg')}{
              order: 6;
          }
          ${mq_max('md')}{
              order: 7;
          }
        }
        &:nth-of-type(4){
          ${mq_max('lg')}{
              order: 7;
          }
        }
        &:nth-of-type(5){
          ${mq_max('lg')}{
              order: 8;
          }
          ${mq_max('md')}{
              order: 10;
          }
        }
        &:nth-of-type(6){
          ${mq_max('lg')}{
              order: 9;
          }
          ${mq_max('md')}{
              order: 11;
          }
        }
        &:nth-of-type(10){
          ${mq_max('lg')}{
              order: 9;
          }
        }
        &:nth-of-type(11){
          ${mq_max('lg')}{
              order: 11;
          }
        }
        &:nth-of-type(12){
          ${mq_max('lg')}{
              order: 12;
          }
        }
      }
      .cta-mobile-row{
          ${mq_max('lg')}{
              order: 13;
          }
      }
    }
  }
`;

export const NavItemLinkStyled = styled.span<NavItemLinkStyledProps>`
  border: 0;
  background: transparent;
  font-family: 'Causten', Arial, sans-serif;
  color: ${color_black};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  cursor: pointer;
  padding: 0 20px 0px 20px;
  ${mq_min('lg')} {
    padding: 0;
    color: ${({ color }) => (color === 'dark' ? color_white : color_black)};
  }

  &:hover{
    > *{
      ${mq_min('lg')} {
        color: #3ACDEF;
        fill: #3ACDEF;
      }
    }
  }
`;

export const NavItemIconStyled = styled(ExpandLessMore)<NavItemIconStyledProps>`
  width: ${getStyleSizes(28)};
  transition: transform 100ms linear;
  ${({ state }) =>`transform: ${state === 'open' ? 'rotateX(180deg);' : 'rotateX(0);'}`};
  ${mq_min('lg')} {
    ${({ state }) =>`
    fill: ${state === 'open' && '#3ACDEF'}`};
  }
`;

export const MenuItemLabel = styled.div<MenuItemLabelProps>`
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  font-size: ${({ state }) => (state === 'open' ? '24px' : '14px')};
  ${mq_min('lg')} {
    font-size: 14px;
    color: ${({ state }) => (state === 'open' && '#3ACDEF')};
  }
`;

export const BasicLinkStyled = styled.span<BasicLinkStyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${color_black};
  height: ${getStyleSizes(60)};
  width: 100%;
  font-size: ${getStyleSizes(18)};
  font-family: 'Causten', Arial, sans-serif;

  ${mq_min('lg')} {
    height: auto;
    font-size: ${getStyleSizes(14)};
    color: ${({ color }) => (color === 'dark' ? color_white : color_black)};
  }

  & span {
    font-weight: 500;
    word-break: keep-all;
  }
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  background: rgba(0, 0, 0, 0.40);
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: -1;
  ${mq_max('lg')} {
    display: none;
  }
`
