import styled from '@emotion/styled';
import {
    color_black,
    color_white,
    color_lightest_grey
} from '@src/styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';

export const SectionContainer = styled('div')`
    background-color: ${color_white};
    background-image: url('https://res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/12001/image_407.webp');
    background-size: 233px;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding: ${getStyleSizes([78, 16, 101])};
    ${mq_min('md')} {
      padding: ${getStyleSizes([78, 32, 100])};
      background-image: url('https://res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/12001/image_408.webp');
      background-size: 347px;
      background-position: right bottom -25px;
    }
    ${mq_min('lg')} {
      padding: ${getStyleSizes([99, 32, 120])};
      background-image: url('https://res.cloudinary.com/spiralyze/image/upload/f_auto/assembly/12001/image_409.webp');
      background-size: 522px;
      background-position: right bottom;
    }
`;

export const HeadlineStyled = styled('div')`
    color: ${color_black};
    text-align: center;
    font-size:  ${getStyleSizes(40)};
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: ${getStyleSizes(50)};
    ${mq_min('md')} {
      font-size:  ${getStyleSizes(48)};
      line-height: 125%;
    }
    ${mq_min('lg')} {
      margin-bottom: ${getStyleSizes(65)};
    }
`

export const ListStyled = styled('div')`
  > div:nth-child(even) {
    flex-direction: row-reverse;
    .list-content{
      ${mq_min('lg')} {
          padding-right:  ${getStyleSizes(114)};
        }
    }
  }
`

export const ListItemStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${getStyleSizes(55)};
  flex-wrap: wrap;
  ${mq_min('md')} {
    justify-content: space-between;
    margin-bottom: ${getStyleSizes(56)};
  }
  ${mq_min('lg')} {
    flex-wrap: nowrap;
    margin-bottom: ${getStyleSizes(89)};
  }
`

export const ListImageStyled = styled('div')`
  width: 100%;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
  ${mq_min('md')} {
    max-width: 100%;
  }
  ${mq_min('lg')} {
    max-width: ${getStyleSizes(620)};
  }
`

export const ListContentStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${mq_min('lg')} {
    justify-content: flex-end;
    padding-right: 72px;
  }
`

export const ListContentWrapper = styled('div')`
  padding: ${getStyleSizes([24,32,0])};
  ${mq_min('lg')} {
    max-width: ${getStyleSizes(454)};
    padding: ${getStyleSizes([0,0,0,20])};
  }
`

export const ListHeadline = styled('div')`
  color: ${color_black};
  font-weight: 500;
  font-size: ${getStyleSizes(24)};
  font-style: normal;
  line-height: 150%;
  margin-bottom: ${getStyleSizes(17)};
`
export const ListCopy = styled('div')`
  color: #444D5E;
  font-size: ${getStyleSizes(18)};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  p{
    margin-bottom:  ${getStyleSizes(8)};
  }
`

export const ImageStyled = styled('img')`
  width: 100%;
  border-radius: 12px;
`

export const CTAContainer = styled('div')`
  text-align: center;
  margin-top: ${getStyleSizes(-7)};
  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(-26)};
  }
  button{
    height: ${getStyleSizes(56)};
    width: ${getStyleSizes(228)};

    span:not(.MuiTouchRipple-root){
      font-size: ${getStyleSizes(18)};
    }
  }
`
