import React, { useState, useEffect } from 'react';

import { HeroBannerContainer,
  BannerHeadlineContainer,
  BannerContainer,
  BannerInner,
  BannerLeftSideContainer,
  BannerRightSideContainer,
  BannerImageStyled,
  BannerContentContainer,
  ImageBannerSliderStyled,
  SliderContainer,
  LogoGridStyled,
  LogoGridItemStyled,
  GradientStyled
 } from '@cms-components/hero-banner-slider/hero-banner.styles';
 import CMSComponentWrapper from '@shared/components/component-wrapper';
 import { CSHeroBannerSliderProps, ImageBannerSliderProps } from './hero-banner.interface';
 import { RichText } from '@src/shared/components';
 import { PrimaryButton } from '@shared/components';
 import { default_breakpoints } from '@src/styles/breakpoints';
 import { SplideSlide } from '@splidejs/react-splide';

export const HeroBannerSlider = ({
  headline,
  content,
  cta,
  hero_image_desktop,
  hero_image_tablet,
  hero_image_mobile,
  image_banner_slider
}: CSHeroBannerSliderProps) => {

  const [isSplideEnabled, setIsSplideEnabled] = useState(false);

    useEffect(() => {
      if (typeof window !== "undefined") {
        setIsSplideEnabled(window.innerWidth < 768);

        const handleResize = () => {
          setIsSplideEnabled(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
      }
    }, []);

  return (
    <HeroBannerContainer
      data-testid="hero-banner__container"
    >
      <BannerContainer
            data-testid={`banner-slider__container`}
          >
            <CMSComponentWrapper
              horizontalPadding={0}
            >
              <BannerInner>
                <BannerLeftSideContainer>
                  <BannerHeadlineContainer>
                    <RichText content={headline} />
                  </BannerHeadlineContainer>
                  <BannerContentContainer>
                    <RichText content={content} />
                  </BannerContentContainer>
                  <PrimaryButton
                    ariaLabel="see more"
                    path={cta?.link_url}
                    label={`${cta?.link_text}`}
                    size={'large'}
                    variant={'contained'}
                    icon={'text'}
                  />
                </BannerLeftSideContainer>
                <BannerRightSideContainer>
                    {hero_image_mobile?.image.url && (
                      <picture>
                        <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={hero_image_desktop?.image.url} />
                        <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={hero_image_tablet?.image.url} />
                        <BannerImageStyled
                            src={hero_image_mobile?.image.url}
                            alt={hero_image_mobile?.image_alt_text}
                          />
                      </picture>
                    )}
                    <GradientStyled></GradientStyled>
                </BannerRightSideContainer>
                {isSplideEnabled ? (
                  <SliderContainer>
                    {image_banner_slider.length > 0 && (
                      <ImageBannerSliderStyled
                        options={{
                          autoWidth: false,
                          perPage: 1,
                          gap: '0',
                        }}
                      >
                        {image_banner_slider.map((item: ImageBannerSliderProps, index: number) => {
                          return (
                            <SplideSlide key={`testimonial__item-${index}`}>
                              {item.slider_image_desktop?.image.url && (
                                <picture>
                                  <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={item.slider_image_desktop?.image.url} />
                                  <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={item.slider_image_tablet?.image.url} />
                                  <BannerImageStyled
                                      src={item.slider_image_mobile?.image.url}
                                      alt={item.slider_image_mobile?.image_alt_text}
                                    />
                                </picture>
                              )}
                            </SplideSlide>
                          )
                        })}
                      </ImageBannerSliderStyled>
                    )}
                    </SliderContainer>
                  ) : (
                    <LogoGridStyled>
                       {image_banner_slider.map((item: ImageBannerSliderProps, index: number) => {
                          return (
                            <LogoGridItemStyled key={`testimonial__item-${index}`}>
                              <picture>
                                <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={item.slider_image_desktop?.image.url} />
                                <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={item.slider_image_tablet?.image.url} />
                                <BannerImageStyled
                                    src={item.slider_image_mobile?.image.url}
                                    alt={item.slider_image_mobile?.image_alt_text}
                                  />
                              </picture>
                            </LogoGridItemStyled>
                          )
                        })}
                    </LogoGridStyled>
                  )}
              </BannerInner>
            </CMSComponentWrapper>
          </BannerContainer>
    </HeroBannerContainer>
  );
};

export const CMSHeroBannerSlider = (props: any) => {
  return <HeroBannerSlider {...props} />;
};
