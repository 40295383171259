import React from 'react';

import { CustomerTestimonialProps } from './testimonial-slider.interface';
import { StandardTestimonialSlider, LargeLightTestimonialSlider, LargeDarkTestimonialSlider } from './components';

import { CSCustomerTestimonial } from '@shared/contentstack/contentstack.interface';
import { testimonialSliderMapper } from './mappers/testimonial-slider.mapper';


export const TestimonialSlider = (props: CustomerTestimonialProps) => {

  const SliderComponent = () =>{

      switch(props.slider_type){
        case 'large light':
          return <LargeLightTestimonialSlider {...props}/>;
        case 'large dark':
          return <LargeDarkTestimonialSlider  {...props}/>;
        default:
          return <StandardTestimonialSlider {...props}/>;
      }
  }
  return (
    <>{SliderComponent()}</>
  );
};


export const MappedTestimonialSliderAccordion = (data: CSCustomerTestimonial) => {
  return <TestimonialSlider {...testimonialSliderMapper(data)} />;
};
