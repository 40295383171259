import React from 'react';
import { PrimaryButton } from '@shared/components';
import {
  HamburgerMenuButton,
  LogoStyled,
  TabletMenuButtons,
  TopMenuStyled,
  MobileLink
} from './top-menu.styles';
import { MainNavIconLink } from '../main-nav/main-nav.styles';
import { useMainNav } from '../../context/main-nav.context';
import { TopMenuProps } from '@cms-components/header/mega-components/top-menu/top-menu.interface';
import BasicLink from '@shared/components/basic-link';
import { ImageComponent } from '@shared/components/image-component/image-component';

export const TopMenu = ({ demo, logo, theme, sign_in_link }: TopMenuProps) => {
  const { navState, toggleNav } = useMainNav();
  const { link_url: demoUrl, link_text: demoLabel } = demo || {};
  const { logo_for_dark_header, logo_for_light_header, alt_text } = logo || {};

  const {
    link_text: signInText,
    link_url: signInUrl,
    external_link: signInIsExternal,
  } = sign_in_link || {};

  const { gatsbyImageData, url } =
  theme === 'light' ? logo_for_light_header : logo_for_dark_header;

  return (
    <TopMenuStyled>
      <LogoStyled to={'/'} color={theme} theme={theme}>
        <ImageComponent
          image={gatsbyImageData}
          alt_text={alt_text || 'Assembly Legal'}
          fallback={url}
          fallback_shadow={false}
        />
      </LogoStyled>
      <TabletMenuButtons>
        <MobileLink>
          <MainNavIconLink color={theme}>
            <BasicLink
              text={signInText}
              url={signInUrl}
              isExternal={signInIsExternal}
            />
          </MainNavIconLink>
          <PrimaryButton
            ariaLabel="get a demo"
            label={demoLabel}
            icon={theme === 'dark' ? 'white' : 'text'}
            size={'small'}
            fontSize={'14px'}
            variant={theme === 'light' ? 'contained' : 'outlined'}
            path={demoUrl}
          />
        </MobileLink>
        <HamburgerMenuButton
          aria-label="Open Menu"
          role="button"
          reverse={theme === 'dark'}
          onClick={() => toggleNav()}
          state={navState === 'opened' ? 'open' : 'closed'}
        />
      </TabletMenuButtons>
    </TopMenuStyled>
  );
};
