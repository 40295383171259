import { CustomerTestimonialProps } from '../testimonial-slider.interface';
import { CSCustomerTestimonial } from '@shared/contentstack/contentstack.interface';
import { CSRichText, CSImage, CSImageAlt } from '@shared/contentstack';

export function testimonialSliderMapper({
  title,
  headline,
  slider_layout,
  slider_type,
  customer_testimonials,
  site_configuration,
}: CSCustomerTestimonial): CustomerTestimonialProps {
  return {
    title,
    headline,
    slider_layout,
    slider_type,
    itemList:
    customer_testimonials?.map(
        ({ customer_name, image, logo, customer_designation, customer_quote }: {
          logo: CSImageAlt;
          customer_name: string;
          image: CSImageAlt;
          customer_designation: string;
          customer_quote: CSRichText;
        }) => ({
          customer_name,
          image,
          logo,
          customer_designation,
          customer_quote
        })
      ) || [],
    site_configuration,
  };
}
