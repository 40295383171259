import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { SectionContainer } from './solutions-list.styles';
import { CSSolutionsListProps } from './solutions-list.interface';
import { RichText } from '@src/shared/components';
import {
    HeadlineStyled,
    CTAContainer,
    ListStyled,
    ListItemStyled,
    ListImageStyled,
    ListContentStyled,
    ImageStyled,
    ListContentWrapper,
    ListHeadline,
    ListCopy
} from './solutions-list.styles';
import { PrimaryButton } from '@src/shared/components';
import { default_breakpoints } from '@src/styles/breakpoints';

export const SolutionsList = ({
  headline,
  solutions_list_items,
  cta
}: CSSolutionsListProps) => {
  return (
    <SectionContainer data-testid="solutions-list_container">
      <CMSComponentWrapper
        maxWidth={1240}
        horizontalPadding={0}
      >
        <HeadlineStyled>
          <RichText content={headline} />
        </HeadlineStyled>
        {solutions_list_items.length > 0 && (
          <ListStyled>
            {solutions_list_items.map((item, index) => {
                return (
                    <ListItemStyled key={index}>
                      <ListImageStyled>
                        <picture>
                          <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={item.image_desktop?.image.url} />
                          <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={item.image_tablet?.image.url} />
                          <ImageStyled
                              src={item.image_mobile?.image.url}
                              alt={item.image_mobile?.image_alt_text}
                            />
                        </picture>
                      </ListImageStyled>
                      <ListContentStyled className="list-content">
                        <ListContentWrapper>
                          <ListHeadline>
                            <RichText content={item.solution_headline} />
                          </ListHeadline>
                          <ListCopy>
                            <RichText content={item.solution_content} />
                          </ListCopy>
                        </ListContentWrapper>
                      </ListContentStyled>
                    </ListItemStyled>
                )
            })}
        </ListStyled>
        )}
        <CTAContainer>
            <PrimaryButton
                ariaLabel={cta?.link_text}
                path={cta?.link_url}
                label={`${cta?.link_text}`}
                size={'large'}
                variant={'contained'}
                icon={'text'}
            />
        </CTAContainer>
      </CMSComponentWrapper>
    </SectionContainer>
  );
};
