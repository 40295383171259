import styled from '@emotion/styled';
import { NavStyledProps } from './nav.interface';
import { color_black, color_white } from '@styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { mq_min, mq_max } from '@styles/breakpoints';
import PersonIcon from '@mui/icons-material/Person';

export const Nav = styled.nav<NavStyledProps>`
  margin-left: ${getStyleSizes(-20)};
  margin-right: ${getStyleSizes(-20)};
  display: ${({ state }) => (state === 'opened' ? 'block' : 'none')};
  overflow-y: auto;
  height: calc(100vh - 70px);
  background: rgba(0, 0, 0, 0.40);
  ${mq_min('lg')} {
    background-color: ${({ color }) =>
      color === 'light' ? color_white : color_black};
    flex-basis: auto;
    flex-shrink: 1;
    padding: ${getStyleSizes([0, -16])};
    overflow-y: inherit;
    min-height: auto;
    max-height: none;
    height: 100%;
  }
`;

export const NavList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-left: 1px;
  @media(max-width: 1110.98px){
    gap: 5px;
  }
  ${mq_max('lg')}{
    flex-wrap: wrap;
    background-color: #FFF;
    margin-left: 0px;
    flex-direction: column;
    gap: 0;
    overflow: hidden;
  }
`;

export const NavIconLink = styled.div`
  display: flex;
  font-size: ${getStyleSizes(18)};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${getStyleSizes([0, 16])};
  height: ${getStyleSizes(60)};
  font-weight: 500;

  ${mq_min('lg')} {
    display: none;
  }
`;

export const PersonIconStyled = styled(PersonIcon)`
  font-size: ${getStyleSizes(18)};
  margin-right: ${getStyleSizes(10)};
`;
