import React from 'react';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { TestimonialContainer, TestimonialInner } from './standard-testimonial-slider.styles';
import { CustomerTestimonialProps } from '../../testimonial-slider.interface';
import { TestimonialSlider as TestimonialSliderComponent } from '@src/shared/components';
import { HeadlineStyled } from './standard-testimonial-slider.styles';

export const StandardTestimonialSlider = ({
  itemList,
  headline,
  slider_layout = '',
  uid,
}: CustomerTestimonialProps) => {
  return (
    <TestimonialContainer slider_layout={slider_layout?.toLowerCase()} data-testid="testimonial_container">
      <CMSComponentWrapper
        uid={uid}
        maxWidth={1440}
        horizontalPadding={0}
      >
        {headline && <HeadlineStyled>{headline}</HeadlineStyled>}
        <TestimonialInner>
          <TestimonialSliderComponent
            slider_layout={slider_layout}
            itemList={itemList}
          />
        </TestimonialInner>

      </CMSComponentWrapper>
    </TestimonialContainer>
  );
};


