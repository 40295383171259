import styled from '@emotion/styled';
import { primaryTheme } from '@styles/themes';
import { getStyleSizes } from '@styles/styles.utils';
import { zIndices } from '@styles/z-indices';
import { color_black, color_white } from '@styles/colors';
import { mq_min } from '@styles/breakpoints';
import {
  MainNavIconLinkProps,
  MainNavWrapperProps,
} from '@cms-components/header/components/main-nav/main-nav.interface';
import PersonIcon from '@mui/icons-material/Person';

export const MainNavWrapper = styled.div<MainNavWrapperProps>`
  background: ${({ color }) => (color === 'light' ? color_white : color_black)};
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${zIndices.header};
`;

export const MainNavStyled = styled.div`
  font-family: ${primaryTheme.typography.fontFamily};
  height: ${getStyleSizes(70)};

  ${mq_min('lg')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${getStyleSizes(60)};
  }
`;

export const DesktopOptions = styled.div`
  display: none;

  ${mq_min('lg')} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const MainNavIconLink = styled.div<MainNavIconLinkProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${getStyleSizes(14)};
  margin-right: ${getStyleSizes(25)};
  color: ${({ color }) => (color === 'light' ? color_black : color_white)};
`;

export const PersonIconStyled = styled(PersonIcon)`
  font-size: ${getStyleSizes(14)};
  margin-right: ${getStyleSizes(5)};
`;
