import React from 'react';

import {
  DropdownContent,
  ItemsSection,
  FeaturedSection,
  SectionRow,
  SectionCol,
  ColItems,
  SectionTitleStyled,
  LinkStyled,
  ItemTitle,
  ItemDescription,
  FeaturedRow,
  FeaturedCol,
  FeaturedContent,
  FeaturedSubHeadline,
  FeaturedTitle,
  FeaturedDate,
  FeaturedImageDesktop,
  FeaturedImageTablet,
  CTAMobileRow
} from './nav-panel.styles';
import {
  MainNavIconLink,
} from '../main-nav/main-nav.styles';
import { PrimaryButton } from '@shared/components';
import BasicLink from '@shared/components/basic-link';
import { ImageComponent } from '@shared/components/image-component/image-component';
import { useMainNav } from '@cms-components/header/context/main-nav.context';
import { NavPanelProps } from './nav-panel.interface';

export const NavPanel = ({ state, featured_section, menu_dropdown, closeAll, demo_button, sign_in_link }: NavPanelProps) => {

  const FALLBACK_IMAGE = String('https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt388bb69251043691/67e2ed5e7ca40a4ea603a1e0/image_1_1440.webp');
  const { closeNav } = useMainNav();
  const { link_url: demoUrl, link_text: demoLabel } = demo_button || {};
  const {
    link_text: signInText,
    link_url: signInUrl,
    external_link: signInIsExternal,
  } = sign_in_link || {};

  return (
    <DropdownContent state={state} className={state === 'open' ? 'open' : ''}>
        <ItemsSection className="item-section">
          <SectionRow className="section-row">
            {menu_dropdown.map((item, dropdownIndex) => (
              item.menu_section_title !== '-' ?
              <SectionCol key={dropdownIndex} className="section-col">
                <SectionTitleStyled>{item.menu_section_title}</SectionTitleStyled>
                <ColItems>
                  {item.menu_section_links.map((link, linkIndex) => {
                    const isPricing = link.menu_section_link.link_text.toLocaleLowerCase() === 'pricing';
                    const isContact = link.menu_section_link.link_text.toLocaleLowerCase() === 'contact us';
                    return (
                      <LinkStyled key={linkIndex} className={isPricing ? 'pricing' : ''} onClick={()=>{closeNav(); closeAll();}}>
                        <BasicLink url={link.menu_section_link.link_url} isExternal={link.menu_section_link.external_link} text={link.menu_section_link.link_text} >
                          <ItemTitle isContact={isContact}>{link.menu_section_link.link_text} {isPricing && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9" fill="none"><path d="M0 0.951418L3.09042 4.04858L0 7.14575L0.951417 8.09717L5 4.04858L0.951417 1.90735e-06L0 0.951418Z" fill="#119FC0"/></svg>
                          )}</ItemTitle>
                          {link.menu_link_description && <ItemDescription>{link.menu_link_description}</ItemDescription>}
                        </BasicLink>
                      </LinkStyled>
                    )
                    })}
                </ColItems>
              </SectionCol> :  <SectionCol key={dropdownIndex} className="section-col"></SectionCol>
            ))}
          </SectionRow>
          <CTAMobileRow className="cta-mobile-row">
            <PrimaryButton
              ariaLabel="get a demo"
              label={demoLabel}
              icon={'text'}
              size={'small'}
              fontSize={'14px'}
              path={demoUrl}
              variant={'contained'}
              color={'miami'}
            />
            <MainNavIconLink color={'light'}>
              <BasicLink
                text={signInText}
                url={signInUrl}
                isExternal={signInIsExternal}
              />
            </MainNavIconLink>
          </CTAMobileRow>
        </ItemsSection>
        <FeaturedSection className="featured-section">
            <SectionTitleStyled>Featured</SectionTitleStyled>
            <FeaturedRow>
              {featured_section.map((item, featuredIndex) => {
                return <FeaturedCol key={featuredIndex}>
                  {!!item?.featured_image_desktop?.gatsbyImageData && (
                    <FeaturedImageDesktop>
                      <ImageComponent
                        image={item?.featured_image_desktop?.gatsbyImageData}
                        alt_text={item?.featured_link?.link_text}
                        fallback={item?.featured_image_desktop?.url || FALLBACK_IMAGE}
                        fallback_shadow={false}
                      />
                    </FeaturedImageDesktop>
                  )}
                  {!!item?.featured_image_tablet?.gatsbyImageData && (
                     <FeaturedImageTablet>
                      <ImageComponent
                        image={item?.featured_image_tablet?.gatsbyImageData}
                        alt_text={item?.featured_link?.link_text}
                        fallback={item?.featured_image_tablet?.url || FALLBACK_IMAGE}
                        fallback_shadow={false}
                      />
                    </FeaturedImageTablet>
                  )}
                  <FeaturedContent>
                    <FeaturedSubHeadline>{item.featured_sub_headline}</FeaturedSubHeadline>
                    <FeaturedTitle onClick={()=>{closeNav(); closeAll();}}>
                      <BasicLink url={item.featured_link.link_url} isExternal={item.featured_link.external_link} text={item.featured_link.link_text} />
                    </FeaturedTitle>
                    <FeaturedDate>{item.featured_date}</FeaturedDate>
                  </FeaturedContent>
                </FeaturedCol>
})}
            </FeaturedRow>
        </FeaturedSection>
    </DropdownContent>
  );
};

export const CMSNavPanel = ({ state, menu_dropdown, featured_section, closeAll, demo_button, sign_in_link }: NavPanelProps) => {
  return !!menu_dropdown ? (
    <NavPanel
      state={state}
      menu_dropdown={menu_dropdown}
      featured_section={featured_section}
      closeAll={closeAll}
      demo_button={demo_button}
      sign_in_link={sign_in_link}
    />
  ) : (
    <></>
  );
};
