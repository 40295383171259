import { MainNavDataProps } from '@cms-components/header/components/main-nav/main-nav.interface';
import { headerMapper } from '@cms-components/header/mappers/header.mapper';
import { useEffect, useState } from 'react';
import { MainNav } from './components/main-nav/main-nav';
import { MegaMainNav } from './mega-components/main-nav/main-nav';
import { fetchHeaderData } from './header.facade';
import { isBrowser } from '@shared/utilities';

export const Header = (data: MainNavDataProps) => {
  return <MainNav data={data} />;
};

export const MegaHeader = (data: MainNavDataProps) => {
  return <MegaMainNav data={data} />;
};


const CMSHeader = () => {
  const baseData = fetchHeaderData(); // STATE
  const [headerData, setHeaderData] = useState<any>(baseData);

  useEffect(() => {
    if (isBrowser()) {
      setHeaderData(baseData);
    }
  }, [baseData, isBrowser]);

  if (!headerData) return <></>;

  const MappedData = headerMapper({ ...headerData });

  return headerData.header_menu_type === 'Standard Menu' ? (
    <Header {...MappedData} />
  ) : (
    <MegaHeader {...MappedData} />
  );;
};

export default CMSHeader;
